












































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import { MemoryCache } from "ts-method-cache";
import { readManagers, readOffices } from "@/store/main/getters";
import {
  dispatchListManagers,
  dispatchListOffices,
} from "@/store/main/actions";
import "@/assets/styles/datatable.css";

@Component
export default class Managers extends Vue {
  public scopeOptions = [
    { name: "User", value: "user" },
    { name: "Manager", value: "manager" },
    { name: "Super Admin", value: "super" },
    { name: "Alpha", value: "alpha" },
  ];

  public columns = [
    {
      header: "ID",
      field: "id",
      sortable: true,
      bodyClass: "data-table-body",
      headerClass: "data-table-header data-table-header-short",
      filterClass: "p-column-filter p-filter-column",
    },
    {
      header: "First Name",
      field: "first_name",
      sortable: true,
      bodyClass: "data-table-body",
      headerClass: "data-table-header",
      filterClass: "p-column-filter p-filter-column",
    },
    {
      header: "Last Name",
      field: "last_name",
      sortable: true,
      bodyClass: "data-table-body",
      headerClass: "data-table-header",
      filterClass: "p-column-filter p-filter-column",
    },
    {
      header: "Email",
      field: "email",
      sortable: true,
      bodyClass: "data-table-body",
      headerClass: "data-table-header",
      filterClass: "p-column-filter p-filter-column",
    },
    {
      header: "Office",
      field: "office_id",
      sortable: true,
      bodyClass: "data-table-body",
      headerClass: "data-table-header",
      filterClass: "p-filter-dropdown",
    },
    {
      header: "Department",
      field: "department",
      sortable: true,
      bodyClass: "data-table-body",
      headerClass: "data-table-header",
      filterClass: "p-column-filter p-filter-column",
    },
    {
      header: "Title",
      field: "title",
      sortable: true,
      bodyClass: "data-table-body",
      headerClass: "data-table-header",
      filterClass: "p-column-filter p-filter-column",
    },
    {
      header: "Permission",
      field: "scope",
      sortable: true,
      bodyClass: "data-table-body",
      headerClass: "data-table-header",
      filterClass: "p-filter-dropdown",
    },
  ];

  public filterConfig = {
    id: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    first_name: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    last_name: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    email: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    office: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    department: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    title: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    scope: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
  };

  public filteredManagers = [];
  public filters = {};
  public rowCount = 0;
  public isLoading = false;

  public get managers() {
    return readManagers(this.$store);
  }

  public get offices() {
    return readOffices(this.$store);
  }

  @MemoryCache()
  public getOffice(id) {
    if (!id) {
      return {};
    }
    const office = this.offices.find((item) => item.id === id);
    return office ?? {};
  }

  @MemoryCache()
  public getScope(scope) {
    if (!scope) {
      return "";
    }
    return this.scopeOptions.find(({ value }) => value === scope).name;
  }

  public updateRowCount(e) {
    this.rowCount = e.filteredValue.length;
  }

  public filterManagers() {
    let filtered = this.managers;
    for (const [key, value] of Object.entries(this.filters)) {
      if (key == "office_id" || key == "scope") {
        if (value !== null && value !== 0 && value !== "") {
          filtered = filtered.filter(
            (manager) => (manager[key] ?? "") === value
          );
        }
      } else if (value !== null && value !== "") {
        filtered = filtered.filter((manager) => {
          if (manager[key] !== null && manager[key] !== "") {
            return (manager[key] ?? "")
              .toString()
              .toLowerCase()
              .includes((value as string).toLowerCase());
          }
          return false;
        });
      }
    }
    this.filteredManagers = filtered;
  }

  public async mounted() {
    window["analytics"]["page"]("/manage/managers");
    this.isLoading = true;
    await dispatchListOffices(this.$store);
    await dispatchListManagers(this.$store);
    this.filteredManagers = this.managers;
    this.rowCount = this.filteredManagers.length;
    this.isLoading = false;
  }
}
